table {
  border-spacing: 0;
}

a:link {
  color: #0079b8;
  text-decoration: none;
}

img {
  border-style: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button:focus {
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
  background: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

section {
  display: block;
}

h1, h2, h3, h4 {
  font-weight: 200;
  letter-spacing: normal;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

h3 {
  line-height: 1.5rem;
}

h4 {
  color: #000;
  font-size: 1.125rem;
  letter-spacing: normal;
  line-height: 1.5rem;
}

h5 {
  color: #565656;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.015em;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

h6 {
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: normal;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
  color: #313131;
}


body p {
  color: #565656;
  font-size: 0.875rem;
  letter-spacing: normal;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: #565656;
  font-size: 16px;
}

h1, h1 > *,
h2, h2 > *,
h3, h3 > * {
  color: rgb(38, 34, 98);
}

body .h-color {
  color: rgb(38, 34, 98);
}

h1 {
  font-size: 36px;
  margin: 0.67em 0;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

body, input[text] {
  color: #888;
  font-weight: 300;
}

* {
  font-family: 'Montserrat', sans-serif;
}

b, strong {
  font-weight: bold;
}

a {
  color: #6fb4ff;
  cursor: pointer;
  cursor: hand;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

body {
  margin: 0;
  font-size: 0.875rem;
  letter-spacing: normal;
  line-height: 1.5rem;
  background-color: #FAFBFC;
  min-height: 100vh;
  width: 100%;
  min-width: 1080px;
}

textarea {
  height: 10em;
  resize: none;
  padding: 0.15rem 0.3rem;
  overflow: auto;
}

input[type=text], input[type=password] {
  border: 1px solid #9a9a9a;
}

input[type=text]:focus, input[type=password]:focus {
  border: 1px solid #0094d2;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.loading-text {
  padding-left: 0.45rem;
  color: #888;
}

.loading-area {
  padding-top: 0.45rem;
}

.required::after {
  content: " *";
  color: red;
}

.validation-error {
  background-color: #FFEEEE;
}

/**
 * Style overrides for noUiSlider
 */
.noUi-connect {
  background: rgb(92, 183, 225);
}

/*
 * Override clarity fonts
 */
body, html, .login,
.login-wrapper .login .title,
.login-wrapper .login .trademark,
.login-wrapper .login .subtitle,
.modal-header .modal-title,
.header .branding .title,
.sidenav .sidenav-content > .nav-link,
.sidenav .nav-group label,
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}


/*
 * Override clarity font weights. They default to 400 for normal, we want 300.
 */
body, body p, .btn,
.btn.btn-sm, .alert-app-level .alert-item .btn,
.btn-sm .btn, .alert-app-level .alert-item .btn .btn,
.btn-group.btn-sm .btn-group-overflow > .dropdown-toggle, .alert-app-level .alert-item .btn-group.btn .btn-group-overflow > .dropdown-toggle,
.dropdown-menu .btn,
.dropdown-menu .btn-secondary,
.dropdown-menu .btn-info,
.dropdown-menu .btn-outline,
.dropdown-menu .btn-outline-primary,
.dropdown-menu .btn-outline-secondary,
.dropdown-menu .btn-outline-warning,
.dropdown-menu .btn-outline-danger,
.dropdown-menu .btn-outline-success,
.dropdown-menu .btn-danger,
.dropdown-menu .btn-primary,
.dropdown-menu .btn-warning,
.dropdown-menu .btn-success,
.dropdown-menu .btn-link,
.dropdown-menu .dropdown-item,
.label, a.label,
header .branding .title,
.header .branding .title,
header .header-nav .nav-text,
.header .header-nav .nav-text,
header .settings .nav-text,
header .header-actions .nav-text,
.header .settings .nav-text,
.header .header-actions .nav-text,
.nav .nav-link,
.sidenav .sidenav-content > .nav-link,
.sidenav .nav-group,
.sidenav .nav-group label,
.clr-vertical-nav.has-nav-groups .nav-group-children .nav-link,
.tooltip.tooltip-top-right > .tooltip-content, .tooltip .tooltip-content.tooltip-top-right,
.tooltip.tooltip-top-left > .tooltip-content, .tooltip .tooltip-content.tooltip-top-left,
.tooltip.tooltip-bottom-right > .tooltip-content, .tooltip .tooltip-content.tooltip-bottom-right,
.tooltip.tooltip-bottom-left > .tooltip-content, .tooltip .tooltip-content.tooltip-bottom-left,
.tooltip.tooltip-right > .tooltip-content, .tooltip .tooltip-content.tooltip-right,
.tooltip.tooltip-left > .tooltip-content, .tooltip .tooltip-content.tooltip-left,
form .form-block > label,
.form .form-block > label,
.stack-view,
.stack-view .stack-block-label,
.datagrid .datagrid-column .datagrid-filter, .datagrid .datagrid-head .datagrid-row-actions .datagrid-filter,
.datagrid .datagrid-body .datagrid-row .datagrid-action-overflow,
.datagrid-foot .column-switch-wrapper .column-switch .switch-header,
.clr-wizard .clr-wizard-stepnav-item,
.clr-wizard .clr-wizard-stepnav-item.active {
  font-weight: 300;
}

/**:focus {outline:none !important}*/

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*
 * Hides the unnecessary "select all" header from the multiselect drop-down for the
 * Degrees multi-select in the Account Creation component.
 */
.create-account__degree-select .p-multiselect-header {
  display: none;
}

/*
 * Causes PDF pages to have space between them and be centered within their container.
 * The react-pdf <Page> component doesn't support inline styles, so we need to use a
 * CSS class and specify style overrides here.
 */
.pdf-page {
  margin-bottom: 1.5rem;
}

.pdf-page > canvas {
  margin: auto;
}

/*
 * The Prime React dropdown selector cannot be styled through its API. Force an
 * override here to match the mocks within the runtime panel. This can be
 * generalized to the rest of the application if needed later.
 */
#runtime-panel .p-dropdown .p-inputtext {
  color: #262262 !important
}

.p-accordion-header-link {
  border: 1px solid #c8c8c8 !important;
  background: transparent !important;
  box-shadow: none !important;
  color: #262262 !important;
}

.p-accordion-content {
  background: transparent !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  font-weight: 400 !important;
}

.hover-style:hover {
  --hover-backgroundColor: var(--app-hover-backgroundColor);
  --hover-border: var(--app-hover-border);
  --hover-color: var(--app-hover-color);
  --hover-boxShadow: var(--app-hover-boxShadow);
  --hover-opacity: var(--app-hover-opacity);
  --hover-textDecoration: var(--app-hover-textDecoration);
}

.hover-style:hover .hover-style:not(:hover) {
  --hover-backgroundColor: initial;
  --hover-border: initial;
  --hover-color: initial;
  --hover-boxShadow: initial;
  --hover-opacity: initial;
  --hover-textDecoration: initial;
}

.p-datatable-frozen-view table {
  border-collapse: separate;
}

.p-datatable-scrollable-wrapper > .p-datatable-frozen-view > .p-datatable-scrollable-body > table {
  border-bottom: 1px solid #c8c8c8;
}

.p-datatable-frozen-view + .p-datatable-unfrozen-view .p-datatable-thead > tr > th:first-child,
.p-datatable-frozen-view + .p-datatable-unfrozen-view .p-datatable-tbody > tr > td:first-child {
  border-left: 0 none;
}

.p-datatable-scrollable-wrapper > .p-datatable-frozen-view > .p-datatable-scrollable-body > table > .p-datatable-tbody > tr > td,
.p-datatable-scrollable-wrapper > .p-datatable-frozen-view > .p-datatable-scrollable-header > .p-datatable-scrollable-header-box > table > .p-datatable-thead > tr > th {
  border-bottom: none;
  border-left: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
  border-top: 1px solid #c8c8c8;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}

body .p-datatable.condensed-table .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.40rem
}

.p-paginator {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-paginator .p-dropdown.p-component.p-inputwrapper-filled {
  height: 2.25rem;
}

body .p-paginator .p-paginator-pages {
  height: auto;
}

.p-button.p-button-secondary {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: #333;
}

.p-button.p-button-secondary:enabled:hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #333;
}

body .p-menu .p-menuitem-link {
  display: block;
}

.p-tabview .p-tabview-nav li {
  padding: 0.571em 0.7em;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent;
  color: #2691d0;
  border: none;
  display: inline-block;
  padding: 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: transparent;
  color: #2691d0;
  border-bottom: 3px solid #2691d0;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background-color: transparent;
  color: #2691d0;
  border: none;
  display: inline-block;
  padding: 0;
}

.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
  display: flex;
  align-items: center;
}

/* React Select styling to increase size of underlying input tag
  Would prefer not to use !important, but seems like how input is styled in
  React-Select is not working as expected. More details can be found here:
  https://github.com/JedWatson/react-select/issues/4106#issuecomment-743959376
*/

.react-select__input,
.react-select__input input {
  width: 100% !important;
}
